import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";

import "./App.scss";
import {
  Download,
  Home,
  Faq,
  Privacy,
  References,
  Terms,
  Unsubscribe,
} from "./pages";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/download">
            <Download />
          </Route>
          <Route path="/references">
            <References />
          </Route>
          <Route path="/references-mobile">
            <References />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/faq-mobile">
            <Faq />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/unsubscribe/:userId">
            <Unsubscribe />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
