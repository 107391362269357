import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useParams } from "react-router-dom";
import "./styles.scss";

const firebaseConfig = {
  apiKey: "AIzaSyDkg3P255ZTrYLEov8PC0pSRtATCYxrF7Y",
  authDomain: "mindstill-69b39.firebaseapp.com",
  projectId: "mindstill-69b39",
  appId: "1:235945413454:web:9e68122a1b75e8c9be2c1f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

function Unsubscribe() {
  let { userId } = useParams();
  console.log("tidid", userId);

  callUnsubscribeHandler(userId);

  return (
    <div className="unsubscribe container">
      {`You've been successfully unsubscribed from Mindstill emails :)`}
    </div>
  );
}

const callUnsubscribeHandler = async (userId) => {
  const handleUnsubscribe = httpsCallable(functions, "handleUnsubscribe");
  handleUnsubscribe({ userId })
    .then((result) => {
      const data = result.data;
      console.log("data", data);
      const sanitizedMessage = data.text;
    })
    .catch((error) => {
      const code = error.code;
      console.log("error", error);
      const message = error.message;
      const details = error.details;
    });
};

export default Unsubscribe;
