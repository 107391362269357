import React, { Component } from 'react'
import cx from 'classnames';

import chevronIcon from 'assets/icons/chevron.png'
import './styles.scss';

class ExpandListItem extends Component {

    handleToggle = () => {
        this.props.onToggle(this.props.id)
    }

    render() {
        return (
            <div className="expand-list-item">
                <div className="toggle-container" onClick={this.handleToggle}>
                    <div className="item-title">
                        {this.props.title}
                    </div>
                    <div className="icon-container">
                        <img
                            className={cx('toggle-icon', {'rotate': this.props.isOpen})}
                            src={chevronIcon}
                            alt='chevron' />
                    </div>
                </div>
                {/* {this.props.isOpen &&  */}
                    <div className={cx('item-body', this.props.isOpen ? 'open' : 'closed')}>
                        {this.props.body}
                    </div>                    
                {/* // } */}
            </div>
        )
    }
}

export default ExpandListItem;
