import React, { Component } from "react";

import { Footer } from "components";
import mindstillLogo from "assets/icons/mindstillLogo.png";
import appleLogo from "assets/icons/appleLogo.png";
import googlePlayLogo from "assets/icons/googlePlayLogo.png";

import "./styles.scss";

const appLinks = {
  appleAppStore: "https://apps.apple.com/us/app/mindstill/id1486286271",
  googlePlay:
    "https://play.google.com/store/apps/details?id=com.mindstill.mindstill",
};

class Home extends Component {
  sendToApp = (storeName) => {
    var win = window.open(appLinks[storeName], "_blank");
    win.focus();
  };

  render() {
    return (
      <div className="home">
        <div className="main-container">
          <div className="logo-container">
            <img
              className="mindstill-logo"
              alt="mindstill logo"
              src={mindstillLogo}
            />
          </div>
          <div className="download-container">
            <p className="download-text">Download Free</p>
            <div className="download-buttons-container">
              <div
                className="download-button"
                onClick={() => this.sendToApp("appleAppStore")}
              >
                <img className="apple-logo" src={appleLogo} />
              </div>
              <div
                className="download-button"
                onClick={() => this.sendToApp("googlePlay")}
              >
                <img className="google-play-logo" src={googlePlayLogo} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
