import React, { Component } from "react";
import "./styles.scss";

class Download extends Component {
  componentDidMount() {
    var win = window.open(
      "https://apps.apple.com/us/app/mindstill/id1486286271",
      "_self"
    );
    win.focus();
  }

  render() {
    return <div className="download container"></div>;
  }
}

export default Download;
