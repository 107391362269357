import cx from "classnames";

import "./styles.scss";

import { Header, Footer } from "components";

function References() {
  const isMobile = window.location.pathname === "/references-mobile";

  return (
    <div className={cx("references", isMobile ? "mobile-padding" : null)}>
      {!isMobile && <Header />}

      <div className="references-container padding">
        <h1> Research on Mindfulness </h1>
        <p className="intro">
          In recent decades, researchers have published thousands of studies on
          the effects of mindfulness practices, validating long expressed
          benefits and discovering new ones* <br /> <br />
          Below is a selection of research, highlighting the potential of
          practices that we reference in Mindstill:
        </p>

        <div className="references-section">
          <h2>Psychoemotional</h2>
          <div className="ref-list">
            <ul>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/24395196/">
                  Reduce stress
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/25686304/">
                  Improve sleep
                </a>
              </li>
              <li>
                <a href="https://psycnet.apa.org/buy/2005-05099-004">
                  Greater feelings of self love
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/22820409/">
                  Reduced feelings of loneliness
                </a>
              </li>
              <li>
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6329416/">
                  Boost general mood and reduce depressive symptons
                </a>
              </li>
              <li>
                <a href="https://link.springer.com/article/10.3758/CABN.7.2.109">
                  Increase attention
                </a>
              </li>
              <li>
                <a href="https://psycnet.apa.org/fulltext/2003-02410-012.html">
                  Greater engagement in interests and values
                </a>
              </li>
              <li>
                <a href="https://www.hindawi.com/journals/ecam/2015/212368/">
                  Lowers negativity bias
                </a>
              </li>
              <li>
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3679190/#R28">
                  A review of hundreds of recent studies on the psycological
                  efffects of meditation
                </a>
              </li>
            </ul>
          </div>

          <h2>Physiological</h2>
          <div className="ref-list">
            <ul>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/12883106/">
                  Supports immune system functioning
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/16951585/1">
                  Reduction in physiological pain
                </a>
              </li>
              <li>
                <a href="https://www.sciencedirect.com/science/article/abs/pii/S0301051100000351">
                  Increased melatonin production at night
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/24485481/">
                  Lowers inflammation in the body
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/16772250/">
                  Improve blood pressure, heart rate variability, and insulin
                  levels
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/24636500/">
                  Increase telomerase activity
                </a>
              </li>
              <li>
                <a href="https://www.sciencedirect.com/science/article/abs/pii/S0149763405802106">
                  Increased bloodflow to the brain and increase in co2
                  elimination
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/6423589/">
                  Improve respiratory flow rate and breath regulation
                </a>
              </li>
            </ul>
          </div>

          <h2>Neurological</h2>
          <div className="ref-list">
            <ul>
              <li>
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4300906/">
                  Slow gray matter atrophy (a substantial factor in neurological
                  "aging"){" "}
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/23717632/">
                  Smaller amygdala (brain center associated with fear)
                </a>
              </li>
              <li>
                <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3004979/">
                  Increase grey matter density of hippocampus (associated with
                  memory)
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/26577539/">
                  Reduced cortisol levels in brain (hormone related to stress)
                </a>
              </li>
              <li>
                <a href="https://www.pnas.org/content/108/50/20254.short">
                  Decreased activity in the default mode network (an area
                  associated with mind wandering and decreased well-being)
                </a>
              </li>
              <li>
                <a href="https://www.pnas.org/content/101/46/16369">
                  Increased high-amplitude-gamma synchrony (associated with
                  feelings of bliss)
                </a>
              </li>
              <li>
                <a href="https://pubmed.ncbi.nlm.nih.gov/1528528/">
                  increased efficiency in the brain's executive attentional
                  network
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className="footnote">
          *There are many flavors of mindfulness practice, and each one comes
          with its own unique benefits. There are also various factors such as
          meditation length, history, control group configuration and so on. For
          certain topics, the research is still being done, and further studies
          are incoming to validate previous findings. <br /> <br />
          Thus, these references aren't necessarily the final verdict on any
          given topic, but can provide insights into the potential benefits of
          practices and acquaint us with some of the most up to date scientific
          understanding.
        </p>
      </div>
    </div>
  );
}

export default References;
