const qna = [
  {
    question: <h2 className="question">What are Mindstill Programs?</h2>,
    answer: (
      <p className="answer">
        Mindstill programs are our way of turning your intentions into habits.{" "}
        <br />
        <br />
        We've taken 5 essential practices from ancient traditions and modern
        techniques, that research has found to be particularly powerful, and
        condensed them into tiny habit forming practices that fit into your day,
        on your schedule. <br /> <br />
        It turns out, the most potent benefits of mindfulness come from
        consistent habits, rather than sporadic feats of meditation. So by
        taking tiny mindfulness breaks/practices throughout your day, you can
        actually get all the benefits of meditation, without having to shave
        your head.
      </p>
    ),
  },
  {
    question: (
      <h2 className="question">
        Is there support beyond programs to keep me on track?
      </h2>
    ),
    answer: (
      <p className="answer">
        Absolutely. We currently offer 4 types of reminders that can support you
        any, or every, day of the week.
        <br />
        <br />
        <span className="underline">Meditation</span>: Simple and potent
        practice reminders to keep you aligned with your goals
        <br />
        <span className="underline">Sleep</span>: To support healthy sleep
        habits and ensure you get the rest you deserve
        <br />
        <span className="underline">Gratitude</span>: Happiness boosters that
        bring appreciation into your life
        <br />
        <span className="underline">Challenges</span>: Tiny and inspiring
        challenges to bring creative presence and fullness into your day
      </p>
    ),
  },
  {
    question: (
      <h2 className="question">Why mindfulness/What are the Benefits?</h2>
    ),
    answer: (
      <div className="answer">
        <p>
          Scientists has uncovered a plethora of benefits from mindfulness
          practice, and the research is ongoing.* <br />
          <br />
          Below are a few exciting findings of the potential of mindful practice
          from the last few decades:
          <br />
          (For a fuller list of research, check out our references page at
          'mindstill.com/references') <br />
          <br />
        </p>

        <ul>
          <li>
            <a href="http://sonjalyubomirsky.com/wp-content/themes/sonjalyubomirsky/papers/LSS2005.pdf">
              Create sustainable happiness
            </a>
          </li>
          <li>
            <a href="https://pubmed.ncbi.nlm.nih.gov/25686304/">
              Improve sleep
            </a>
          </li>
          <li>
            <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6329416/">
              Improve mood and reduce depression
            </a>
          </li>
          <li>
            <a href="https://pubmed.ncbi.nlm.nih.gov/24395196/">
              Reduce stress
            </a>
          </li>
          <li>
            <a href="https://link.springer.com/article/10.3758/CABN.7.2.109">
              Increase attention
            </a>
          </li>
          <li>
            <a href="https://pubmed.ncbi.nlm.nih.gov/24485481/">
              Lowers inflammation in your body
            </a>
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: <h2 className="question">What even is mindfulness?</h2>,
    answer: (
      <p className="answer">
        Simply put: mindfulness is fully experiencing your moment. <br />
        <br />
        This could be feeling your breath as it moves through you, fully
        enjoying a bite of your meal, feeling the relaxing sensations of a warm
        shower, or any other part of your experience.
        <br />
        In this way, every moment is a potential gateway to experience
        mindfulness, and the peace and fullness it can bring, no matter where
        you are in your life.
        <br />
        <br />
        Even more so, mindfulness is experiencing your moment in a way that is
        open and kind. Open, in the sense that it allows for all of your
        experience, and kind in the sense that you can hold your thoughts,
        emotions, people, or sensations in a space that is accepting and
        non-judgmental
        <br />
        <br />
        Then there is formal, or intentional, mindfulness, this is where you
        create a specific space and time to practice in a particular way. This
        could be cultivating gratitude using a journal before sleep, or
        intentionally drawing 5 deep breaths while riding the bus. <br />
        <br />
        Most of our lives are lived off the cushion so to speak. But the beauty
        is that formal practice can support our whole lives, by shifting how we
        relate and respond to our inner world in a controlled setting, so that
        we can more skillfully orient and experience them when we are walking to
        our job or in a conversation with a friend. <br />
        <br />
        (For a deeper dive into what mindfulness is, how it can benefit you, and
        a taste of direct practice, jump into our Introduction course inside the
        Learn tab)
      </p>
    ),
  },
  {
    question: <h2 className="question">How should I explore mindstill?</h2>,
    answer: (
      <p className="answer">
        Upon entering Mindstill, we compile a set of personalized first steps
        for you to explore, based on your intentions and what others have found
        helpful, as an introduction and springboard into Mindstill. <br />
        <br />
        If you want to build a sustainable meditation habit: our programs offer
        our most comprehensive and enjoyed way of integrating mindfulness into
        your life
        <br />
        <br />
        If you want to access and hone specific skills, and their respective
        benefits: feel free to instantly drop into a practice in our gym, or
        peruse our courses in the learn tab for anything that strikes interest{" "}
        <br />
        <br />
        And if you're wanting to get a better night's sleep or simply relax
        before bedtime: explore a sleep meditation or story before bed.
      </p>
    ),
  },
  {
    question: <h2 className="question">How long should I meditate for?</h2>,
    answer: (
      <p className="answer">
        There is no practice too small. <br />
        One full breath can change the course of a whole conversation or day.
        <br /> <br />
        While longer practices can often yield greater results, consistency and
        habit formation are the central factor in the long term benefits of
        practice. <br /> <br />
        For the right meditation habit is one that is actually sustainable.{" "}
        <br />
        Thus, micro-meditations (of one breath or just a couple of minutes) have
        been found to be one of the most powerful ways to cultivate and develop
        a sustainable practice, and thus reap the deepest benefits of practice,
        which is why our programs are designed around this very concept. <br />{" "}
        <br />
        You can also trust your gut; if you ever feel the desire to sit longer
        or shorter, during one session or in general, try leaning into it and
        seeing how it feels.
      </p>
    ),
  },
  {
    question: <h2 className="question">How often should I practice?</h2>,
    answer: (
      <p className="answer">
        The fruits of practice come from consistency. <br />
        As research and countless people have discovered, the benefits of
        mindfulness begin to show as it becomes woven into your life, even if
        its just a few minutes each day. <br />
        <br />
        As with many skills, the most challenging part is in the beginning,
        where you're working through the inertia of your old patterns and the
        neural pathways of your new habit are just beginning to form. <br />
        <br />
        Our recommendation, especially in the beginning, is to practice
        everyday, even if it's just one deep breath before you get out of bed.{" "}
        <br />
        <br />
        We built Mindstill Programs to give you an easy and powerful way to
        weave mindfulness into your life. Micro meditations delivered thrice
        daily, on your schedule, help you develop a consistent habit. <br />
        <br />
        This way, the seeds of mindfulness begin to grow into your life,
        creating a space that the full benefits of practice can grow from.
      </p>
    ),
  },
  {
    question: <h2 className="question">When should I practice?</h2>,
    answer: (
      <p className="answer">
        Whenever you are present, you invite the potential for joy, peace, and
        clarity. <br />
        Yet creating time for intentional practice is often the key in forming a
        sustainable habit. <br />
        <br />
        Three classic times to practice are:
        <br />
        In the morning - beginning your day with the right momentum.
        <br />
        In the evening - preparing for sleep and digesting the day gone by.
        <br />
        or in the middle of your day - to center yourself and breath in the
        midst of it all.
        <br />
        <br />
        This is why we've built our programs around 3 meditations, placed
        centrally during these times.
        <br />
        <br />
        To maximize the effectiveness of our programs, you can try a technique
        called habit stacking, where you stack a practice you want to habituate
        (2 min gratitude practice) on top of a habit you already do (like
        drinking coffee in the morning). E.G. before or while your coffee water
        is boiling, set aside 2 min towards gratitude practice. This method of
        habit stacking can also be used with practices from the gym or any habit
        you want to instantiate.
      </p>
    ),
  },
  {
    question: <h2 className="question">What does progress look like?</h2>,
    answer: (
      <p className="answer">
        There are an infinite amount of ways that progress can be experienced in
        your life. <br />
        From feeling less anxiety throughout your day, having an easier time
        falling asleep, or hearing reflections from your friends or partner that
        you seem more yourself. <br />
        <br />
        Just like a new diet or exercise routine, results may not show up during
        or after the first session or two. The most powerful benefits of
        practice emerge and blossom with time, as they become more and more a
        part of who you are. <br />
        <br />
        As diverse as the signs of progress can be, here are a few that many
        notice within weeks of practice:
        <br />
        <span className="padding-left">
          {" "}
          Feeling less reactive to conflict and stressful situations{" "}
        </span>{" "}
        <br />
        <span className="padding-left">
          {" "}
          Noticing more joy and peace within your mind{" "}
        </span>{" "}
        <br />
        <span className="padding-left">
          {" "}
          Feeling better equipped to relate and communicate friends and family{" "}
        </span>{" "}
        <br />
        <span className="padding-left">
          {" "}
          Having an easier time falling asleep{" "}
        </span>{" "}
        <br />
        <span className="padding-left">
          {" "}
          Entering flow more often and easily during your day{" "}
        </span>{" "}
        <br />
        <span className="padding-left">
          {" "}
          A general feeling of uplift or ease as you move throughout your life{" "}
        </span>{" "}
        <br />
        <span className="padding-left">
          {" "}
          And many more unique ways that emerge from your own life{" "}
        </span>{" "}
        <br />
      </p>
    ),
  },
  {
    question: (
      <h2 className="question">
        Why is sleep so heavily featured in Mindstill?
      </h2>
    ),
    answer: (
      <p className="answer">
        Sleep is one of the most important things you do everyday. <br />
        <br />
        Getting good sleep reduces stress, improves your mood, enhances
        relationships, helps maintain a healthy weight, improves your
        performance, and much more. <br />
        In short, every part of your body and mind are dependent on the sleep
        you get. <br />
        <br />
        Unfortunately, not getting the sleep you need can affect you just as
        deeply in the wrong direction. <br />
        <br />
        In this modern world, there are many facets and habits ingrained into
        our lives that detract from getting the right amount of sleep. Luckily,
        many of them are malleable.
        <br />
        Research, and the experience of millions of people, have attested to the
        power of simple habit changes and practices that you can do just before
        sleep to ensure you get the sleep that you need. <br />
        <br />
        Essentially, sleep is so heavily featured because your sleep affects
        nearly every part of your life, and we want to ensure that you have all
        the tools and support you need to make sure all boats rise with its
        tide.
      </p>
    ),
  },
  {
    question: (
      <h2 className="question">What's the deal with Mindstill's stats?</h2>
    ),
    answer: (
      <p className="answer">
        Our stats aim to give you reflections on your practice in the moment and
        the progress of your journey over time. <br />
        <br />
        <span className="underline">Mental Charge</span>: A representation of
        how clear you mind is in the moment. <br />
        Clarity increases as you meditate, before slowly decreasing with time.{" "}
        <br />
        <span className="underline">Mindful Reps</span>: Like a muscle, your
        mind is enhanced through reps of exercise. <br />
        One mental rep = one conscious breath. A Habit of about 100 mindful reps
        (about 10 minutes) per day can have a measurable impact on your brain,
        and your well-being, in a matter of weeks. <br />
        <span className="underline">Mindful Days</span>: A Mindful day is any
        day where you devoted even one minute towards practice. <br />
        We keep track of those days to show you how far you've come.
      </p>
    ),
  },
  {
    question: <h2 className="question">What is the risk free policy?</h2>,
    answer: (
      <p className="answer">
        We're confident that Mindstill will improve your life. <br />
        But if you don't find Mindstill valuable, we'll give you a full refund;
        no questions asked. <br />
        <br />
        We feel passionate about providing an impactful and sustainable guide to
        mindfulness and its benefits to everyone, and think that money should
        never be the reason that someone can't access Mindstill. <br />
        <br />
        If you want to try all of Mindstill, but can't afford our subscription,
        simply email us and we'll give you access to Mindstill for free. <br />
        <br />
        In either case, reach out by emailing: support@mindstill.com.
      </p>
    ),
  },
  {
    question: (
      <h2 className="question">
        What if I have a question that wasn't answered above?
      </h2>
    ),
    answer: (
      <p className="answer">
        Please reach out by emailing: support@mindstill.com
      </p>
    ),
  },
];

export default qna;
