import React, { Component } from "react";
import mindstillLogo from "assets/icons/mindstillLogo.png";
import { Link } from "react-router-dom";

import "./styles.scss";

class Header extends Component {
  sendToApp = () => {
    var win = window.open(
      "https://apps.apple.com/us/app/mindstill/id1486286271",
      "_blank"
    );
    win.focus();
  };

  render() {
    return (
      <div className="header">
        <Link to={"/"}>
          <img
            className="header-logo"
            alt="mindstill logo"
            src={mindstillLogo}
          />
        </Link>
        {this.props.appLink && (
          <div className="header-text" onClick={this.sendToApp}>
            Try For Free
          </div>
        )}
      </div>
    );
  }
}

export default Header;
