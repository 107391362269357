import React, { Component } from "react";
import { Link } from "react-router-dom";
import appLogoPurple from "assets/icons/logoPurple.png";

import "./styles.scss";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-container">
          <div className="logo-container">
            <img className="logo" alt="mindstill logo" src={appLogoPurple} />
          </div>
          <div className="resources-container">
            <h2>Resources</h2>
            <div className="links">
              <Link to={"./faq"}>
                <p>FAQ</p>
              </Link>
              <Link to={"./references"}>
                <p>Research</p>
              </Link>
            </div>
          </div>
          <div className="text-container">
            <p className="text">© Mindstill LLC 2021 All Rights Reserved</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
