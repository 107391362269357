import './styles.scss'

function Privacy() {
  return (
    <div className='privacy container'>
        <div>
            <h2>Mindstill</h2>
            <h1>Privacy Policy</h1>
            <p>
        Mindstill LLC. (“Mindstill,” “us,” ”we,” or “our”) is committed to
        protecting and respecting your privacy in connection with your use of
        our website, www.Mindstill.com (the “Website(s)”), applications (“Apps”)
        and other products, services and features thereof (the Website, the Apps
        and such other products, services and features are collectively referred
        to herein as the “Product” or “Products”, which may be updated from
        time-to-time at the sole discretion of Mindstill). This privacy policy
        (“Privacy Policy”) and any other documents referred to herein set forth
        the basis on which any personal information we collect from you, or that
        you provide to us, in connection with the Products will be processed by
        us. Please read the following carefully to understand our practices
        regarding your personal information and how we will collect, use and
        disclose your personal information. If you have an unresolved privacy or
        data use concern that we have not addressed satisfactorily, please
        contact us at help@mindstill.com.</p><p>1. INFORMATION WE MAY COLLECT FROM
        YOU</p><p>We may collect and process the following information about you: •
        Personal information including, for example, your name, e-mail address,
        telephone, information about your usage of the Products and information
        collected by tracking technologies as further described below that may
        identify you as an individual or allow online or offline contact with
        you as an individual.</p><p>• For individuals who participate in a
        Mindstill Family plan, we may collect additional personal information,
        for example, home address in order to verify family member status.</p><p>•
        If, as a primary account holder of a Mindstill Family plan, you choose
        to invite additional family members to become subsidiary account
        holders, we will ask you for their name and email address. We will use
        this information to invite him or her to join the Products. We store
        this information for the sole purpose of sending invitations and
        confirming family member status. If your subsidiary account holder(s)
        would like us to delete their information, they can do so by contacting
        the primary account holder of their family plan to be removed.</p><p>•
        Mindstill does not collect or process credit or debit card (“Payment
        Card”) information. Apple and Google collect Payment Card information
        with respect to in-app purchases made through the Apps, and our payment
        processor collects Payment Card information with respect to purchases
        made through the Websites. Such payment processors generally provide us
        with some limited information related to you, such as a unique token
        that enables you to make additional purchases using the information
        they’ve - stored, and your card’s type, expiration date, billing
        address, and the last four digits of your card number.</p><p>• For
        individuals using the Products in connection with a Community client
        account as described in section 6, business information such as your
        company name, and company email address to the extent that you provide
        such information</p><p>• Facebook profile information, such as name, email
        address, and Facebook ID, if you choose to log in to the Products
        through Facebook.</p><p>• Device Information such as operating system
        version, device type, and system performance information.</p><p>•
        Information collected via tracking technologies, as fully described in
        section 4</p><p>• If you choose to invite a “Buddy” to use the Products
        using our Buddy system, we will ask you for their name and email
        address. We will use this information to invite him or her to join the
        Products. We store this information for the sole purpose of sending
        invitations and tracking the success of our Buddy program. If your Buddy
        would like us to delete his or her information, they can do so by
        opting-out as described in each invitation or by contacting us
        at help@Mindstill.com.</p><p>• If you choose to have your account verified
        to confirm your status as a student, we may allow a third party platform
        to access the specific personal information you provide in order to
        perform the verification.</p><p>2. CONFIDENTIALITY AND SECURITY</p><p>The
        security of your personal information is important to us. We follow
        generally accepted standards to protect the personal information
        submitted to us, both during transmission and once it is received. If
        you have any questions about the security of your personal information,
        you can contact us at help@Mindstill.com. Except as described under the
        “Disclosure of Your Information” section below, we do not provide your
        personal information to any third party without your specific consent,
        as defined by applicable law.</p><p>3. NEWSLETTERS OR OTHER ELECTRONIC
        COMMUNICATIONS</p><p>If you sign up to receive promotional materials from
        us via email we will use the information you give us to provide the
        communications you have requested. If you inform us that you wish to
        cancel email promotional materials by selecting “unsubscribe” at the
        bottom of such communication or by emailing us at help@Mindstill.com, we
        will remove you from our mailing list. If you no longer wish to receive
        push notifications, you may turn them off at the device level.</p><p>If you
        provide your phone number to us directly or through a third-party for
        the specific purpose of receiving an SMS message with a link to our
        Apps, you will receive such SMS message (the “SMS Service”). Standard
        text message rates will apply.</p><p>4. TRACKING TECHNOLOGIES</p><p>Mindstill
        and our analytics partners use technologies such as cookies, beacons,
        tags, and scripts to enable a service to recognize your device so you
        don't have to provide the same information several times during one
        task, recognize that you may have already given a password so you don't
        need to do it for every web page requested, and to measure how people
        are using the Products.</p><p>We use local storage, such as HTML5, to store
        content information and preferences. Third parties with whom we partner
        to provide certain features on the Products also use HTML5 to collect
        and store information. Various browsers may offer their own management
        tools for removing HTML5.</p><p>We partner with third parties, such as
        Facebook and Google, to manage our advertising of the Products on other
        sites or platforms as well as across your other devices based on your
        past visits to our website. Our third party partners may use
        technologies such as cookies to gather information about your activities
        within the Products to deliver such advertising to you, such as
        retargeting ads. We will acquire consent from you in order to use such
        trackers for advertising purposes to the extent required by applicable
        law. We currently do not respond to do-not-track signals. For more
        information about interest-based ads, including how to opt-out of having
        your web-browsing information used for behavioral advertising purposes,
        please visit www.aboutads.info/choices. Please note that this does not
        opt you out of being served ads. You may continue to receive generic ads
        on these third party platforms. You may also opt out of receiving ads
        across devices by adjusting your ad preference in your Google
        account.</p><p>We use third party trackers to let us know when users have
        visited the Products by “clicking-through” our sponsored advertising or
        content hosted on third party platforms. The Products use Google
        Analytics code to gather statistical information. Google Analytics sets
        cookies to help us accurately estimate the number of visitors to the
        Products and the volumes of usage of the Products. This is done to
        ensure that the Products are available when you want them and are fast.
        For more information on how Google Analytics processes this information,
        visit www.google.com/analytics.</p><p>MOBILE ANALYTICS</p><p>We use mobile
        analytics software to allow us to better understand the functionality of
        our mobile software on your phone. This software may record information
        such as how often you engage with the Products, the events that occur
        within the Products, aggregated usage and performance data, and where
        the Applications were downloaded from. We may link the information we
        store within the analytics software to any personal information you
        submit within the mobile application.</p><p>LOG FILES</p><p>As true of most
        websites, we gather certain information and automatically and store it
        in log files. This information may include Internet Protocol (IP)
        addresses, browser type, internet service provider (ISP), referring/exit
        pages, operating system, date/time stamp, and/or clickstream data.</p><p>If
        you receive the HTML-formatted version of our email newsletter, your
        opening of the newsletter is notified to us and saved. Your clicks on
        links in the newsletter are also saved. These and the open statistics
        are used in aggregate form to give us an indication of the popularity of
        the content and to help us make decisions about future content and
        formatting.</p><p>5. WHERE WE STORE YOUR PERSONAL INFORMATION</p><p>All
        information you provide to us through the Products is stored on our
        secure servers located in the US. Any payment transactions will be
        encrypted using SSL technology; all payment information is stored with
        our payment processor and is never stored on Mindstill’s servers. Where
        we have given you (or where you have chosen) a password which enables
        you to access certain parts of the Products, you are responsible for
        keeping this password confidential. We ask you not to share a password
        with anyone, and suggest that your change your password
        frequently.</p><p>The transmission of information via the Internet is not
        completely secure. Although we will do our best to protect your personal
        information, we cannot guarantee the security of your information
        transmitted to the Products; any transmission is at your own risk. Once
        we have received your information, we will use strict procedures and
        security features to try to prevent unauthorized access.</p><p>6. CORPORATE
        AND OTHER COMMUNITY SHARING</p><p>There is interest by large consumer
        communities (companies, universities, hospitals, etc.) (“Communities”)
        to introduce the Products to their employees and members. If you have
        registered to use the Products through a code or other registration
        credential furnished by a Community (a “Community Subscription”), the
        Community will have access to your email address, and the date you
        registered to use the Products, and the date on which you last used the
        Products. The Community will also have access to general usage
        information on an anonymized and aggregated basis with all other members
        of the Community that are using the Products.</p><p>Your Community may also
        have access to your usage information on an individual basis if you have
        provided appropriate consent under applicable law for such sharing. For
        example, many employers are offering incentive programs based on
        employee participation in health & wellness programs and desire to
        better understand how each of their individual employees is using the
        Products.</p><p>7. SINGLE SIGN-ON</p><p>You can log into our Products using
        sign-in services such as Facebook Connect or an Open ID provider. These
        services will authenticate your identity, provide you the option to
        share certain personal information (such as your name and email address)
        with us, and to pre-populate our sign-up form. Services like Facebook
        Connect give you to the option to post information about your activities
        on our Products or to your profile page to share with others within your
        network.</p><p>8. ACCESS TO AND DELETION OF PERSONAL INFORMATION</p><p>Upon
        request, Mindstill will provide you with information about whether we
        hold, or process on behalf of a third party, any of your personal
        information. To request this information, please
        email help@Mindstill.com.</p><p>You may access your personal information to
        modify or update at any time via an online account, or by
        emailing help@Mindstill.com.</p><p>To request the deletion of your personal
        data that we have on file please email us at help@Mindstill.com. Upon
        request, Mindstill will permanently and irrevocably anonymize your data
        such that it can never be reconstructed to identify you as an
        individual.</p><p>We will respond to your request in a timely fashion as
        dictated by applicable law.</p><p>9. USES MADE OF THE INFORMATION</p><p>We use
        information held about you in the following ways:</p><p>• To ensure that
        content provided by the Products is presented in the most effective
        manner for you and for your computer or other device.</p><p>• To provide
        you with information, products or services related to Mindstill that you
        will facilitate your engagement with the Products.</p><p>• To provide you
        with promotional communications, such as email, to the extent that you
        have provided consent to receive such communications under applicable
        law.</p><p>• To provide you with customer service communications.</p><p>• To
        carry out our obligations arising from any agreements entered into
        between you and us.</p><p>• To allow you to participate in interactive
        features of the Products, when you choose to do so.</p><p>• To notify you
        about changes to the Products.</p><p>• To understand your broad,
        non-specific geographic location to help us identify groups of users by
        general geographic market (such as zip code, state or country).</p><p>• To
        account for applicable sales taxes based on zip codes provided to our
        payment processors for purchases made through the Websites.</p><p>• To
        inform your Community about your registration and use of the Products as
        described under Corporate and Other Community Sharing in section 6
        above.</p><p>• To serve our advertisements to you through third party
        platforms, such as Facebook or Google, on other sites and apps or across
        your devices, to the extent that you have provided consent for such uses
        under applicable law</p><p>• To provide you with the SMS Service to the
        extent that you have provided consent for such practices under
        applicable law.</p><p>• We may also ask you to complete surveys that we use
        for research purposes, although you do not have to respond to them.</p><p>•
        Details of transactions you carry out through the Products and of the
        fulfillment of your Product orders.</p><p>• Details of your visits to and
        interactions with the Products including, but not limited to, traffic
        data, location data, weblogs and other communication data, whether this
        is required for our own billing purposes or otherwise and the resources
        that you access.</p><p>• If you are an existing customer, we will only
        contact you by electronic means (e-mail or in-Product communication)
        with information about products and services similar to those which were
        the subject of a previous sale to you.</p><p>• If you choose to become a
        primary account holder of a Mindstill Family plan, we may cross
        reference the address you’ve provided with that of any subsidiary
        account holder seeking to join your Mindstill Family plan.</p><p>• If you
        choose to become a subsidiary account holder of a Mindstill Family plan,
        we may cross reference the address you’ve provided with that of any
        primary account holder administering your Mindstill Family plan.</p><p>• If
        you choose to have your account verified to confirm your status as a
        student, we may allow a third party platform to access the specific
        personal information you provide in order to perform the
        verification.</p><p>10. DISCLOSURE OF YOUR INFORMATION</p><p>We may disclose
        your personal information to any member of our group, which means our
        subsidiaries, our ultimate holding company and its subsidiaries.</p><p>We
        may also disclose your personal information to third parties as
        follows:</p><p>• In some circumstances, based on your specific requests, we
        may need to disclose your personal information to a third party so that
        they can provide a service you have requested from such party, or
        fulfill a request for information from such party. An example of this is
        the SMS Service.</p><p>• In some circumstances, we may disclose the
        personal information that you have provided to Mindstill to a third
        party that offers and/or provides goods or services complementary to our
        own for the purpose of enhancing our users’ experiences by offering you
        integrated or complementary functionality, complementary services or
        bundled pricing options.</p><p> • If Mindstill’s service providers (like
        hosting, market analytics, and payment service providers) require this
        information to provide services to Mindstill. Mindstill requires each of
        its service providers to agree to maintain the confidentiality and
        security of your personal information.</p><p>• In the event that we sell or
        buy any business or assets, in which case we may disclose your personal
        information to the prospective seller or buyer of such business or
        assets.</p><p>• If Mindstill or substantially all of our assets are
        acquired by a third party, in which case personal information held by us
        about our customers will be one of the transferred assets.</p><p>• If we
        are under a duty to disclose or share your personal information in order
        to comply with any legal obligation such as to comply with a subpoena,
        bankruptcy proceedings, similar legal process, or in order to enforce or
        apply our agreements with you; or to protect the rights, property, or
        safety of Mindstill, our customers, or others. This includes exchanging
        information with other companies and organizations for the purposes of
        fraud protection and credit risk reduction.</p><p>• With your Community, if
        your subscription is a Community Subscription, as described under
        Corporate and Other Community Sharing above.</p><p>• With third parties,
        such as Facebook, in order to serve Mindstill advertisements on such
        third party platforms, to the extent that you have consented to such
        practices under applicable law.</p><p>• If you choose to have your account
        verified to confirm your status as a student, we may allow a third party
        platform to access the specific personal information you provide in
        order to perform the verification.</p><p>11. WITHDRAWAL OF CONSENT FOR
        PROCESSING ACTIVITIES</p><p>To the extent that you have provided
        appropriate consent under applicable law to certain processing
        activities, such consent can be withdrawn at any time by
        emailing help@Mindstill.com.</p><p>12. LINKS TO THIRD PARTY SITES</p><p>The
        Products may, from time to time, contain links to and from the Products
        of our partner networks, advertisers and affiliates. If you follow a
        link to any of these external websites, please note that these websites
        have their own privacy policies and that we do not accept any
        responsibility or liability for these websites or their policies. Please
        check these policies before you submit any personal information to these
        external websites.</p><p>13. SOCIAL MEDIA WIDGETS</p><p>Our Products include
        social media features, such as the Facebook Like button, and widgets,
        such as the “Share This” button, or interactive mini-programs. These
        features may collect your Internet protocol address, which page you are
        visiting on or Products, and may set a cookie to enable the feature to
        function properly. Social media features and widgets are hosted by a
        third party or hosted directly on our Products. Your interactions with
        these features are governed by the privacy statement of the company
        providing it.</p><p>14. HEALTHKIT</p><p>Apple iOS users may opt-in to allow
        the Products to provide data regarding the amount of minutes meditated
        to the Apple iOS “Health” application for display. This data will not be
        shared with third parties or used for marketing purposes.</p><p>15. USE OF
        Mindstill BY MINORS</p><p>You must be 18 years of age, or the age of
        majority in your province, territory or country, to sign up as a
        registered user of the Products. Individuals under the age of 18, or the
        applicable age of majority, may utilize the Products only with the
        involvement and consent of a parent or legal guardian, under such
        person's account and otherwise subject to these Terms.</p><p>16.
        INFORMATION POSTED ON FORUMS</p><p>Mindstill users may have the ability to
        post content to one or more Mindstill forums. All such users may request
        and obtain removal of such posted content by contacting Mindstill
        at help@Mindstill.com and specifically identifying the content to be
        removed. Please be advised that any such removal does not ensure
        complete or comprehensive removal of all traces of the content posted on
        the Mindstill forum(s).</p><p>17. CHANGES TO OUR PRIVACY POLICY</p><p>We may
        update this Privacy Policy to reflect changes to our information
        practices. If we make any material changes we will notify you by
        in-Product message, email (sent to the e-mail address specified in your
        account) or by means of a notice in the Products prior to the change
        becoming effective. We encourage you to periodically review this page
        for the latest information on our privacy practices.</p><p>18.
        CONTACT</p><p>Questions, comments and requests regarding this privacy
        policy are welcomed and should be addressed
        to help@Mindstill.com.</p><p>This Privacy Policy is effective and was last
        updated on November 16, 2019.</p><p>19. REQUIRED DISCLOSURE</p><p>Mindstill is
        subject to the regulatory enforcement powers of the U.S. Federal Trade
        Commission. In certain situations, Mindstill may be required to disclose
        personal data in response to lawful requests by public authorities,
        including to meet national security or law enforcement requirements.</p><p>
      </p>
      </div>
    </div>
  )
}

export default Privacy
