import React, { Component } from "react";
import cx from "classnames";

import qna from "./qna.js";
import "./styles.scss";

import { Header, Footer } from "components";
import { ExpandListItem } from "uibrary";

class Faq extends Component {
  constructor() {
    super();
    this.state = {
      openList: [],
    };
  }

  onToggle = (id) => {
    // console.log('tsrg', e.target)
    // const id = e.target.id
    const isOpen = this.state.openList.includes(Number(id));

    console.log("id is:", id);

    this.setState({
      openList: isOpen ? [] : [Number(id)],
    });
  };

  render() {
    const isMobile = window.location.pathname === "/faq-mobile";

    return (
      <div className="faq">
        {!isMobile && (
          <div>
            <Header />
            <h1 className="faq-header"> FAQ</h1>
          </div>
        )}
        <div className={cx("qna-container", isMobile ? "margin-mobile" : null)}>
          {qna.map((el, id) => (
            <ExpandListItem
              id={id}
              onToggle={this.onToggle}
              title={el.question}
              body={el.answer}
              isOpen={this.state.openList.includes(id)}
              key={id}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Faq;
